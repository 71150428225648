import { useRef } from 'react';
import { getText } from '@veraio/strank';
import { Skeleton, ImageGallery, Col, Row, Image, Button, Icon, Modal } from 'components';
import { galleryContainer } from './styles';

const HotelGallery = ({ hotelImages, className }) => {
  const modalRef = useRef();

  return (
    <div css={galleryContainer} {...(className && { className })}>
      <ImageGallery>
        <section>
          <Row gap={4}>
            <Col lg={6} md={6} sm={6} xs={6}>
              {hotelImages?.length ? (
                <Image src={hotelImages[0]} className="first-image" />
              ) : (
                <Skeleton className="first-image" />
              )}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Row gap={4}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {hotelImages?.length ? (
                    <Image src={hotelImages[1]} className="second-image" />
                  ) : (
                    <Skeleton className="second-image" />
                  )}
                </Col>
              </Row>
              <Row gap={4}>
                <Col lg={6} md={6} sm={6} xs={6}>
                  {hotelImages?.length ? (
                    <Image src={hotelImages[2]} width={'100%'} height={180} className="third-image" />
                  ) : (
                    <Skeleton width={'100%'} height={180} />
                  )}
                </Col>
                <Col lg={6} md={6} sm={6} xs={6} className="last-image-container">
                  {hotelImages?.length ? (
                    <>
                      <Image src={hotelImages[3]} className="last-image" />
                      <Button small type="link" onClick={() => modalRef.current?.open()} className="button">
                        <div className="action">
                          <p className="label">{getText('allPhotos')}</p>
                          <Icon material iconName="expand_circle_right" color="white" />
                        </div>
                      </Button>
                    </>
                  ) : (
                    <Skeleton className="last-image" />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        <Modal fullscreen ref={modalRef} closeIcon className="gallery-modal-container">
          <ImageGallery images={hotelImages} carouselView carouselImagesHeight={'calc(100vh - 250px)'} />
        </Modal>
      </ImageGallery>
    </div>
  );
};

export default HotelGallery;
